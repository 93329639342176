import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Template, Col1, Col2 } from '@Templates/Login'
import LoginForm from '@Components/LoginForm'
import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import { useMessageContext } from '@Context/MessageContext'

import { Typography } from 'antd'
import './index.less'

const { Text, Title } = Typography

const useQuery = () => new URLSearchParams(useLocation().search)

const LoginPage = () => {
  const { t } = useTranslation(['common'])
  const location = useLocation()
  const messageContext = useMessageContext()
  const history = useHistory()
  const query = useQuery()

  const [redirectTo] = useState(
    location?.state?.from ? location?.state?.from : '/'
  )
  const [loginState, setLoginState] = useState(true)

  const [error] = useState(query.get('error'))
  useEffect(() => {
    if (error) {
      messageContext.setMessage({
        value: t(error),
        type: 'error'
      })

      query.delete('error')
      history.replace({
        search: query.toString()
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Template title='Login to Strattic'>
      <Col1>
        <Title>{loginState ? t('login') : t('two_fa')}</Title>
        <Text type='secondary'>
          {loginState ? t('login_text') : t('two_fa_description')}
        </Text>
        <LoginForm
          redirectTo={redirectTo}
          search={redirectTo?.search}
          setLoginState={setLoginState}
          loginState={loginState}
        />
        {loginState ? <Links t={t} /> : null}
        <footer />
      </Col1>
      <Col2>
        <div className='sunset-wrapper'>
          <Title className='sunset-title'>
            Starting January 1st 2025 <br />
            Strattic will no longer be available
          </Title>
          <Text className='sunset-text' type='secondary'>
            Since acquiring Strattic, Elementor Hosting has evolved tremendously
            in performance and security. <br />
            Hence, we are moving forward with Elementor Hosting solutions and
            sunsetting Strattic Hosting.
            <br />
            We encourage you to explore the benefits of migrating to Elementor
            Hosting!
          </Text>
        </div>
      </Col2>
    </Template>
  )
}

export default LoginPage

function Links ({ t }) {
  return (
    <>
      <Text>
        <Link
          className='login-next-step'
          to='/forgot-password'
          onClick={() => {
            window.dataLayer.push({
              event: 'fireEvent',
              event_name: 'element_click',
              action_type: 'forgot_password_link'
            })
            AnalyticsManager.getInstance().trackClick({
              action: 'to_reset_password',
              label: EVENT_CATEGORIES.LOG_IN
            })
          }}
        >
          {t('forgot_password')}
        </Link>
      </Text>
      <br />
      <br />
      {/* <p>
        {t('no_account')}{' '}
        <a
          className='login-next-step'
          href='https://link.strattic.com/create-account-legacy/'
          onClick={() => {
            window.dataLayer.push({
              event: 'fireEvent',
              event_name: 'element_click',
              action_type: 'signup_link'
            })
            AnalyticsManager.getInstance().trackClick({
              action: 'to_sign_up',
              label: EVENT_CATEGORIES.LOG_IN
            })
          }}
        >
          {t('create_an_account')}
        </a>
      </p> */}
    </>
  )
}
